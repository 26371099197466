<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <b-overlay :show="showOverlay" rounded="sm">
      <b-card style="max-width: 100%; width: 100%" class="mb-2">
        <b-form class="form" @submit.stop.prevent="onSaveClicked">
          <b-card-body>
            <h1 class="h1-profile">{{ $t("PROFILE.ACCOUNT") }}</h1>
            <b-form-group>
              <img
                :src="getAvatarUrl"
                class="rounded img-thumbnail float-left profile-avatar"
                alt="Avatar"
              />
              <b-button-group vertical class="img-button-group" size="sm">
                <b-button
                  variant="link"
                  class="img-button-change"
                  @click="changeAvatar"
                  ><img src="media/svg/buttons/edit.svg" />
                  {{ $t("PROFILE.CHANGE") }}</b-button
                >
                <b-button
                  v-if="avatar.avatarChanged"
                  variant="link"
                  class="img-button-undo"
                  @click="undoAvatar"
                  ><img src="media/svg/buttons/undo.svg" />
                  {{ $t("PROFILE.UNDO") }}</b-button
                >
                <b-button
                  v-if="dto.avatar != null && avatar.imgDataUrl != null"
                  variant="link"
                  @click="deleteAvatar"
                  class="img-button-delete"
                  ><img src="media/svg/buttons/delete.svg" />
                  {{ $t("PROFILE.DELETE") }}</b-button
                >
              </b-button-group>
            </b-form-group>
            <my-upload
              v-if="avatar.show"
              field="image"
              @crop-success="cropSuccess"
              @crop-upload-success="cropUploadSuccess"
              @crop-upload-fail="cropUploadFail"
              v-model="avatar.show"
              :width="150"
              :height="150"
              :langType="getCurrentLang()"
              url=""
              :params="avatar.params"
              :headers="avatar.headers"
              img-format="png"
            ></my-upload>
            <h3 class="h3-profile">{{ $t("PROFILE.ABOUT") }}</h3>
            <b-form-row class="form-row">
              <div class="form-row-block">
                <label class="profile-label" for="name-input">{{
                  $t("PROFILE.NAME")
                }}</label>
                <b-form-input
                  id="name-input"
                  :state="validateState('name')"
                  v-model.trim="$v.dto.name.$model"
                  @input="$v.dto.name.$touch()"
                  class="form-row-block-input"
                  :placeholder="$t('PROFILE.SURNAME_ENTER')"
                  aria-describedby="name-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback
                  id="name-live-feedback"
                  class="error"
                  v-if="!$v.dto.name.required"
                >
                  {{ $t("AUTH.VALIDATION.NAME_REQUIRED") }}
                </b-form-invalid-feedback>
                <b-form-invalid-feedback
                  id="name-live-feedback"
                  class="error"
                  v-if="!$v.dto.name.maxLength"
                >
                  {{
                    $t("AUTH.VALIDATION.NAME_MAX_LENGTH", {
                      length: $v.dto.name.$params.maxLength.max,
                    })
                  }}
                </b-form-invalid-feedback>
              </div>
              <div v-if="windowWidth" class="divider"></div>
              <div class="form-row-block">
                <label class="profile-label" for="surname-input">{{
                  $t("PROFILE.SURNAME")
                }}</label>
                <b-form-input
                  id="surname-input"
                  :state="validateState('surname')"
                  v-model.trim="$v.dto.surname.$model"
                  @input="$v.dto.surname.$touch()"
                  class="form-row-block-input"
                  :placeholder="$t('PROFILE.SURNAME_ENTER')"
                  aria-describedby="surname-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback
                  id="surname-live-feedback"
                  v-if="!$v.dto.surname.required"
                >
                  {{ $t("AUTH.VALIDATION.SURNAME_REQUIRED") }}
                </b-form-invalid-feedback>
                <b-form-invalid-feedback
                  id="surname-live-feedback"
                  class="error"
                  v-if="!$v.dto.surname.maxLength"
                >
                  {{
                    $t("AUTH.VALIDATION.SURNAME_MAX_LENGTH", {
                      length: $v.dto.surname.$params.maxLength.max,
                    })
                  }}
                </b-form-invalid-feedback>
              </div>
            </b-form-row>
            <b-form-row class="form-row">
              <div class="form-row-block">
                <label class="profile-label" for="email-input">{{
                  $t("PROFILE.EMAIL")
                }}</label>
                <b-form-input
                  id="email-input"
                  :state="validateState('email')"
                  v-model.trim="$v.dto.email.$model"
                  @input="$v.dto.email.$touch()"
                  class="form-row-block-input"
                  :placeholder="$t('PROFILE.EMAIL_ENTER')"
                  aria-describedby="input-email-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback
                  id="input-email-live-feedback"
                  v-if="!$v.dto.email.required"
                >
                  {{ $t("AUTH.VALIDATION.EMAIL_REQUIRED") }}
                </b-form-invalid-feedback>
                <b-form-invalid-feedback
                  id="input-email-live-feedback"
                  v-if="!$v.dto.email.email"
                >
                  {{ $t("AUTH.VALIDATION.EMAIL_FORMAT") }}
                </b-form-invalid-feedback>
                <b-form-invalid-feedback
                  id="input-email-live-feedback"
                  v-if="!$v.dto.email.maxLength"
                >
                  {{ $t("AUTH.VALIDATION.EMAIL_MAX_LENGTH") }}
                </b-form-invalid-feedback>
                <div class="radio-chanel">
                  <b-form-radio v-model.trim="selectedPriority" value="Email">
                    <span class="radio-chanel-text">{{
                      $t("PROFILE.DEFAULT_CHANEL")
                    }}</span>
                  </b-form-radio>
                </div>
              </div>
              <div v-if="windowWidth" class="divider"></div>
              <div class="form-row-block">
                <label class="profile-label" for="phone-input">{{
                  $t("PROFILE.PHONE")
                }}</label>
                <b-form-input
                  id="phone-input"
                  v-model.trim="dto.phone"
                  class="form-row-block-input"
                  :placeholder="$t('PROFILE.PHONE_ENTER')"
                ></b-form-input>
                <div class="radio-chanel">
                  <b-form-radio
                    v-model.trim="selectedPriority"
                    class="radio-chanel-block"
                    value="Phone"
                  >
                    <span class="radio-chanel-text">{{
                      $t("PROFILE.DEFAULT_CHANEL")
                    }}</span>
                  </b-form-radio>
                </div>
              </div>
            </b-form-row>
            <b-form-row class="form-row">
              <div class="form-row-block">
                <label class="profile-label" for="telegram-input">{{
                  $t("PROFILE.TELEGRAM")
                }}</label>
                <b-form-input
                  id="telegram-input"
                  v-model.trim="dto.telegramId"
                  class="form-row-block-input"
                  :placeholder="$t('PROFILE.TELEGRAM_ENTER')"
                ></b-form-input>
                <div class="radio-chanel">
                  <b-form-radio
                    v-model.trim="selectedPriority"
                    class="radio-chanel-block"
                    value="Telegram"
                  >
                    <span class="radio-chanel-text">{{
                      $t("PROFILE.DEFAULT_CHANEL")
                    }}</span>
                  </b-form-radio>
                </div>
              </div>
            </b-form-row>
            <h3 class="h3-profile">{{ $t("PROFILE.SECURITY") }}</h3>
            <b-form-row class="form-row">
              <div class="form-row-block">
                <label class="profile-label" for="password-input">{{
                  $t("PROFILE.PASSWORD")
                }}</label>
                <b-form-input
                  id="password-input"
                  type="password"
                  v-model="$v.security.password.$model"
                  :state="validateThisState('password')"
                  @input="$v.security.password.$touch()"
                  class="form-row-block-input"
                  :placeholder="$t('PROFILE.PASSWORD_ENTER')"
                  aria-describedby="password-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback
                  id="password-live-feedback"
                  v-if="!$v.security.password.minLength"
                  >{{
                    $t("AUTH.VALIDATION.PASSWORD_MIN_LENGTH", {
                      length: $v.security.password.$params.minLength.min,
                    })
                  }}
                </b-form-invalid-feedback>
                <b-form-invalid-feedback
                  id="password-live-feedback"
                  v-if="!$v.security.password.maxLength"
                  >{{
                    $t("AUTH.VALIDATION.PASSWORD_MAX_LENGTH", {
                      length: $v.security.password.$params.maxLength.max,
                    })
                  }}
                </b-form-invalid-feedback>
              </div>
              <div v-if="windowWidth" class="divider"></div>
              <div class="form-row-block">
                <label
                  class="profile-label"
                  for="password-confirmation-input"
                  >{{ $t("PROFILE.PASSWORD_CONFIRMATION") }}</label
                >
                <b-form-input
                  id="password-confirmation-input"
                  type="password"
                  v-model="$v.security.repeatPassword.$model"
                  :state="validateThisState('repeatPassword')"
                  @input="$v.security.repeatPassword.$touch()"
                  class="form-row-block-input"
                  :placeholder="$t('PROFILE.PASSWORD_CONFIRMATION_ENTER')"
                  aria-describedby="password-confirmation-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback
                  id="password-confirmation-live-feedback"
                  >{{
                    $t("AUTH.VALIDATION.PASSWORD_IDENTICAL")
                  }}</b-form-invalid-feedback
                >
              </div>
            </b-form-row>
            <b-button
              class="save-button"
              variant="success"
              type="submit"
              :disabled="loading"
              >{{ $t("PROFILE.SAVE") }}</b-button
            >
          </b-card-body>
        </b-form>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
// todo: refactor this towel

import { API_REQUEST } from "@/core/services/store/api.module";
//import Common from "../../common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import { validationMixin } from "vuelidate";
import {
  email,
  maxLength,
  minLength,
  required,
  sameAs,
} from "vuelidate/lib/validators";

import myUpload from "vue-image-crop-upload";
import {
  SET_AVATAR,
  SET_USERNAME,
} from "../../core/services/store/auth.module";
// import data2blob from "vue-image-crop-upload/utils/"

export default {
  name: "profile",
  mixins: [validationMixin],
  components: { "my-upload": myUpload },
  updated() {},
  data() {
    return {
      passwordMinLength: 6,
      save: {
        resource: "/api/profile/save",
        requestType: "POST",
        requestParams: {},
      },
      dto: {
        name: null,
        surname: null,
        email: null,
        phone: null,
        telegramId: null,
        password: null,
        passwordConfirmation: null,
        isEmailPriority: false,
        isPhonePriority: false,
        isTelegramPriority: false,
      },

      security: {
        password: "",
        repeatPassword: "",
      },

      avatar: {
        show: false,
        params: {
          name: "image",
        },
        headers: {
          Token: localStorage.id_token,
        },
        imgDataUrl: null,
        avatarChanged: false,
      },

      loading: false,

      selectedPriority: null,
      showOverlay: true,
    };
  },

  validations: {
    dto: {
      email: {
        required,
        email,
        maxLength: maxLength(254),
      },
      name: {
        required,
        maxLength: maxLength(35),
      },
      surname: {
        required,
        maxLength: maxLength(35),
      },
    },
    security: {
      password: {
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      repeatPassword: {
        sameAsPassword: sameAs("password"),
      },
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("MENU.PROFILE") }]);
  },

  created: async function () {
    await this.$store
      .dispatch(API_REQUEST, {
        resource: "/api/profile/",
        requestType: "GET",
      })
      .then((response) => {
        this.dto = response;
        this.avatar.imgDataUrl =
          this.dto.avatar != null
            ? this.dto.avatar
            : process.env.BASE_URL + "media/users/default.jpg";
        this.security.password = this.dto.password = "";
        this.security.repeatPassword = this.dto.passwordConfirmation = "";
      })
      .catch(this.onError);

    this.onCheckPriority();
    this.showOverlay = false;
  },

  methods: {
    validateState(name) {
      //console.log("check validate State");
      const { $dirty, $error } = this.$v.dto[name];
      return $dirty ? !$error : null;
    },
    validateThisState(name) {
      console.log("check this validate State");
      const { $dirty, $error } = this.$v.security[name];
      return $dirty ? !$error : null;
    },

    onCheckPriority: function () {
      if (this.dto.isPhonePriority == true) {
        this.selectedPriority = "Phone";
      } else if (this.dto.isEmailPriority == true) {
        this.selectedPriority = "Email";
      } else if (this.dto.isTelegramPriority == true) {
        this.selectedPriority = "Telegram";
      } else {
        this.selectedPriority = null;
      }
    },

    async onSaveClicked() {
      console.log("-----Validate Start-----");
      this.$v.dto.$touch();
      this.$v.security.$touch();
      if (this.$v.dto.$anyError || this.$v.security.$anyError) {
        console.log("-----Validate Error-----");
        return;
      }
      console.log("-----Validate Success-----");

      this.loading = true;
      this.errors = [];

      if (this.avatar.avatarChanged && this.avatar.imgDataUrl != null) {
        const avatarRequest = {
          resource: "api/resource",
          requestType: "POST",
          requestParams: {},
        };
        const fmData = new FormData();
        fmData.append("image", this.data2blob(this.avatar.imgDataUrl, "png"));
        avatarRequest.dto = fmData;
        await this.$store
          .dispatch(API_REQUEST, avatarRequest)
          .then((response) => {
            this.dto.avatar = response;
          })
          .catch(this.onError);
      } else if (this.avatar.imgDataUrl == null) {
        this.dto.avatar = null;
      }
      this.avatar.avatarChanged = false;
      this.$store.commit(SET_AVATAR, this.dto.avatar);

      const request = this.save;
      request.dto = this.dto;

      if (this.selectedPriority == "Email") {
        if (this.dto.email != null) {
          this.dto.isEmailPriority = true;
          this.dto.isPhonePriority = false;
          this.dto.isTelegramPriority = false;
        } else {
          this.$bvToast.toast("The selected priority has an empty field", {
            title: "Warning",
            variant: "warning",
            autoHideDelay: 5000,
          });

          return;
        }
      }

      if (this.selectedPriority == "Phone") {
        if (this.dto.phone != null) {
          this.dto.isPhonePriority = true;
          this.dto.isEmailPriority = false;
          this.dto.isTelegramPriority = false;
        } else {
          this.$bvToast.toast("The selected priority has an empty field", {
            title: "Warning",
            variant: "warning",
            autoHideDelay: 5000,
          });

          return;
        }
      }

      if (this.selectedPriority == "Telegram") {
        if (this.dto.telegramId != null) {
          this.dto.isTelegramPriority = true;
          this.dto.isEmailPriority = false;
          this.dto.isPhonePriority = false;
        } else {
          this.$bvToast.toast("The selected priority has an empty field", {
            title: "Warning",
            variant: "warning",
            autoHideDelay: 5000,
          });

          return;
        }
      }
      if (this.selectedPriority == "" || this.selectedPriority == null) {
        this.dto.isTelegramPriority = false;
        this.dto.isEmailPriority = false;
        this.dto.isPhonePriority = false;
      }

      if (
        this.dto.password != null &&
        this.dto.password.length > 0 &&
        this.dto.password != this.dto.passwordConfirmation
      ) {
        this.$bvToast.toast("Passwords should be equal", {
          title: "Warning",
          variant: "warning",
          autoHideDelay: 5000,
        });

        return;
      }

      this.dto.password = this.security.password;

      this.$store
        .dispatch(API_REQUEST, request)
        .then((response) => {
          this.$store.commit(SET_USERNAME, {
            name: this.dto.name,
            surname: this.dto.surname,
            fullname: this.dto.name + " " + this.dto.surname,
          }); // :todo возможно, это не так

          this.dto.id = response.id;
          this.$v.dto.$reset();
          this.$bvToast.toast("Saved successfully", {
            title: "Success",
            variant: "success",
            autoHideDelay: 5000,
          });
          this.loading = false;
        })
        .catch(this.onError);
    },

    onError: function (response) {
      if (response && response.config) response = response.data;

      this.$bvToast.toast(
        response && response.message ? response.message : "Unknown error",
        {
          title: "Error",
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },
    getCurrentLang() {
      return localStorage.getItem("language") || "en";
    },
    changeAvatar() {
      this.avatar.show = !this.avatar.show;
    },
    undoAvatar() {
      this.avatar.imgDataUrl =
        this.dto.avatar != null
          ? this.dto.avatar
          : process.env.BASE_URL + "media/users/default.jpg";
      this.avatar.avatarChanged = false;
    },
    deleteAvatar() {
      this.avatar.imgDataUrl = null;
      this.avatar.avatarChanged = true;
    },
    /**
     * crop success
     *
     * [param] imgDataUrl
     * [param] field
     */
    cropSuccess(imgDataUrl) {
      console.log("-------- crop success --------");
      this.avatar.imgDataUrl = imgDataUrl;
      this.avatar.avatarChanged = true;
    },
    /**
     * upload success
     *
     * [param] jsonData  server api return data, already json encode
     * [param] field
     */
    cropUploadSuccess(jsonData, field) {
      console.log("-------- upload success --------");
      console.log(jsonData);
      console.log("field: " + field);
    },
    /**
     * upload fail
     *
     * [param] status    server api return error status, like 500
     * [param] field
     */
    cropUploadFail(status, field) {
      console.log("-------- upload fail --------");
      console.log(status);
      console.log("field: " + field);
    },
    data2blob(data, format) {
      let mimes = {
        jpg: "image/jpeg",
        png: "image/png",
        gif: "image/gif",
        svg: "image/svg+xml",
        psd: "image/photoshop",
      };
      let mime = mimes[format];
      data = data.split(",")[1];
      data = window.atob(data);
      let ia = new Uint8Array(data.length);
      for (let i = 0; i < data.length; i++) {
        ia[i] = data.charCodeAt(i);
      }

      return new Blob([ia], {
        type: mime,
      });
    },
  },

  computed: {
    getAvatarUrl: function () {
      return this.avatar.imgDataUrl != null
        ? this.avatar.imgDataUrl
        : process.env.BASE_URL + "media/users/default.jpg";
    },
    hideForm: function () {
      return this.loading;
    },
    windowWidth() {
      return window.innerWidth >= 850;
    },
  },
};
</script>
<style>
.vue-image-crop-upload .vicp-wrap {
  height: 85%;
  width: 85%;
  max-width: 600px;
  max-height: 460px;
}
.profile-avatar {
  max-width: 100px;
  max-height: 100px;
  padding: 0;
  border: 0;
}
.svg-img {
  max-width: 15px;
  max-height: 15px;
}
.img-button-group {
  margin-left: 10px;
  text-align: left;
}
.img-button-change {
  text-align: left;
  color: #6d7392;
}
.img-button-undo {
  text-align: left;
  color: #ffa500;
}
.img-button-delete {
  text-align: left;
  color: #f04a4f;
}
.h1-profile {
  margin-bottom: 30px;
}
.h3-profile {
  color: #6d7392;
  margin-top: 40px;
}
.profile-label {
  color: #b5b5c3;
  padding-left: 5px;
}
.form-row {
  padding-left: 5px;
}
.form-row-block {
  max-width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
}
.form-row-block-input {
  max-width: 100%;
  width: 400px;
}
.divider {
  margin-right: 35px;
}
.radio-chanel {
  margin-top: 10px;
}
.radio-chanel-block {
  margin-top: 5px;
}
.radio-chanel-text {
  color: #6d7392;
}
.save-button {
  margin-top: 40px;
}
.hidden {
  display: none;
}
.form-group {
  margin-bottom: 1rem;
}
</style>
